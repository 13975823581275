export default [
  {
    name: 'English',
    languageCode: 'en',
    countryCode: 'GB',
  },
  {
    name: 'Magyar',
    languageCode: 'hu',
    countryCode: 'HU',
  },
  {
    name: 'Español',
    languageCode: 'es',
    countryCode: 'ES',
  },
];
